<template>
  <div class="index">
    <div style="">
      <home-view v-if="active === 0"></home-view>
      <type-view v-if="active === 1"></type-view>
      <stat-view v-if="active === 2"></stat-view>
      <log-view v-if="active === 3" ></log-view>
     
    </div>

    <van-tabbar @change="menuBtn" v-model="active" active-color="#1A9CFC">
      
      <van-tabbar-item
        >首页
        <img
          slot="icon"
          slot-scope="props"
          :src="props.active ? homeYes : homeNo"
        />
      </van-tabbar-item>
      <van-tabbar-item
        >分类
        <img
          slot="icon"
          slot-scope="props"
          :src="props.active ? typeYes : typeNo"
        />
      </van-tabbar-item>
      <van-tabbar-item
        >收藏
        <img
          slot="icon"
          slot-scope="props"
          :src="props.active ? starYes : starNo"
      /></van-tabbar-item>

      <van-tabbar-item icon="setting-o"
        >记录
        <img
          style="margin-left: 4px"
          slot="icon"
          slot-scope="props"
          :src="props.active ? logYes : logNo"
        />
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { getAdmins } from "@/utils/adminDate";
import { setById } from "@/service/base";

export default {
  name: "medicalHome",

  components: {
    homeView: () => import("../../views/medicalFormula/medicalHome.vue"),
    statView: () => import("../../views/medicalFormula/medicalMark.vue"),
    logView: () => import("../../views/medicalFormula/medicalLog.vue"),
    typeView: () => import("../../views/medicalFormula/medicalType.vue"),
  },

  data() {
    return {
      minHeight: window.innerHeight + "px",
      active: this.$store.state.medicalMenu,
      homeYes: require("@/assets/medical/home_yes.png"),
      homeNo: require("@/assets/medical/home_no.png"),
      typeYes: require("@/assets/medical/type_yes.png"),
      typeNo: require("@/assets/medical/type_no.png"),
      starYes: require("@/assets/medical/star_yes.png"),
      starNo: require("@/assets/medical/star_no.png"),
      logYes: require("@/assets/medical/log_yes.png"),
      logNo: require("@/assets/medical/log_no.png"),
      // datekey: Date.now(),
    };
  },
  mounted() {
    document.getElementById("app").style.height = "100vh";
    this.getById();
    console.log("进入了辅助间");
  },
  //  activated() {
  //   console.log("执行");
    
  //     this.btnEvent()
  // },

  methods: {
    async getById() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$route.query.memberId,
        this.$route.query.channel
      );

      await setById(arrays);
    },
    menuBtn(e) {
      this.$store.commit("medicalMenu", e);
      if (e !== 3) {
        this.$store.commit("medicalMenu_log", "left");
      }
    },
  
  },
};
</script>

<style scoped lang="scss">
.index {
  height: auto;
  background: #f6f6f6;
}
</style>
